import { Position, Toaster, Intent } from '@blueprintjs/core';
import { handleBeforeUnload } from '../../App';
import sectionStore from '../../store/sectionStore';

const Notification = Toaster.create({
  position: Position.TOP,
  intent: Intent.DANGER,
});

export const showNotification = (message, type, location) => {
  let messageString = message;
  let status;
  let showMessage = true;
  if (!type || type === 'danger') {
    console.error('error', message, location);
  }
  if (typeof message === 'string' || message instanceof String) {
    if (
      message === 'Unauthenticate' ||
      message === 'Invalid collection id' ||
      message === 'invalid token' ||
      message?.toLowerCase() === 'Invalid version id'
    ) {
      history.pushState({}, '', window.location.pathname);
      if (
        sectionStore.noAccessToken &&
        sectionStore.blockerModalType === '401'
      ) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
      window.location.href = '/401';
    }
  } else {
    status = message?.status;
    if (
      typeof message?.message === 'string' ||
      message?.message instanceof String
    ) {
      messageString = message.message;
    }
    if (status === 422) {
      // TODO: Handling 422 error
    }
    if (status === 500) {
      // TODO: Handling 500 error
      showMessage = false;
    }
    if (status === 504) {
      // TODO: Handling 504 error
    }
  }
  if (showMessage) {
    Notification.show({
      message: messageString ? messageString : 'Something went wrong',
      timeout: 3000,
      intent: type ? type : 'danger',
      icon: type ? 'tick' : 'warning-sign',
    });
  }
};
