import { track } from 'react-tracking';
import { sendEvent } from './analyticsApi';
import { getSession } from './sessionTracker';
import sectionStore from '../store/sectionStore';

const BATCHING_COUNT = 0;
let globalBatch = [];

const sendBatch = async batch => {
  const session = await getSession();
  if (!session) return;

  const email = sectionStore?.userData?.email;
  if (email) {
    batch = batch.map(b => ({ ...{ email: email }, ...b }));
  }
  const userDetails = sectionStore?.userData;
  if (userDetails) {
    const user = userDetails;
    batch = batch.map(b => ({ ...{ user: !!user }, ...b }));
  }
  const requests = batch.map(b => {
    return sendEvent(session?.sessionId, b.name, b).catch(e => {
      console.log(`Error sending event ${b.name}`, e);
    });
  });

  try {
    await Promise.all(requests);
  } catch (e) {
    console.log('error calling the Batch ', e);
    return;
  }
};

const processDispatch = data => {
  globalBatch.push(data);
  if (globalBatch.length >= BATCHING_COUNT) {
    sendBatch(globalBatch);
    // reset batch
    globalBatch = [];
  }
};

const eventTracker = Component =>
  track(
    { app: 'studio' },
    {
      dispatch: processDispatch,
    }
  )(Component);

export default eventTracker;
