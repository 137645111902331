import React from 'react';
import PropTypes from 'prop-types';
import ErrorNavbar from '../common-components/screen/ErrorNavbar';
import Screen from '../common-components/screen/Screens';
import ErrorImage from '../assets/500.png';
import { Messages } from '../constants/text';
import Button from '../common-components/button/Button';
import { LINKS } from '../constants/links';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: { message: '', stack: '' },
      info: { componentStack: '' },
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({ error, info }, () => {
      Sentry.captureMessage(error?.message || error);
    });
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <ErrorNavbar />
          <Screen
            image={ErrorImage}
            text={Messages.ErrorMessageTextBoundary}
            subText={Messages.ErrorMessageSubTextBoundary}
            imgStyle={{ width: 'auto' }}
          >
            <Button
              title={'Return home'}
              onClick={() => window.open(LINKS.HOME, '_blank')}
            />
          </Screen>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
