import React, { Suspense, useContext, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import { ThemeContext } from './ThemeContext';
import ConnectionChecker from './common-components/wrapper/connectionChecker';
import { observer } from 'mobx-react-lite';

const Error401 = lazy(() => import('./errors/Error401'));
const Error500 = lazy(() => import('./errors/Error500'));
const Error404 = lazy(() => import('./errors/Error404'));
const Main = lazy(() => import('./Main'));

// eslint-disable-next-line no-unused-vars

const AppRouter = observer(({ store }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={theme}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path="/collection/:collection_id"
              element={
                <ConnectionChecker>
                  <Main store={store} />
                </ConnectionChecker>
              }
            />
            <Route path="/401" element={<Error401 />} />
            <Route path="/403" element={<Error401 />} />
            <Route path="/500" element={<Error500 />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
});
export default AppRouter;
