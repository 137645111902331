import { getSessionId } from './analyticsApi';
import sectionStore from '../store/sectionStore';

const SESSION_EXPIRE_TIME = 10 * 60 * 1000; // 10 mins

const isSessionValid = () => {
  try {
    const userSession = sectionStore.userSection;

    if (!userSession) return false;
    const session = userSession;
    if (!session?.expires) return false;
    if (Date.now() > session.expires) return false;
    return true;
  } catch (e) {
    return false;
  }
};

export const getSession = async () => {
  if (!isSessionValid()) {
    const userId = sectionStore.userData?.id;
    if (!userId) return;

    const org = sectionStore.userData?.org;
    if (!org) return;

    try {
      const res = await getSessionId(userId, org);
      const sessionId = res.data.sessionId;

      let sessionData = {
        sessionId: sessionId,
        expires: Date.now() + SESSION_EXPIRE_TIME,
      };
      sectionStore.setUserSession(sessionData);
    } catch (e) {
      console.log('Error calling the getSessionId ', e);
      return;
    }
  }
  return sectionStore.userSection;
};
