import { observable } from 'mobx';
import Cookies from 'js-cookie';

const sectionStore = observable({
  sections: [],
  renderSection: [],
  toggleSectionPanel: false,
  collectionJson: {},
  collectionJsonState: true,
  fetchProductState: true,
  activePage: null,
  checkConnection: true,
  fetchDataFlag: false,
  accessToken: Cookies.get('access_token') ? Cookies.get('access_token') : null,
  uploadData: [],
  fetchUploadDataFlag: false,
  loading: false,
  SaveModalState: false,
  blockerModalState: false,
  blockerModalType: '401',
  warningModalState: false,
  noAccessToken: false,
  userData: {},
  userSection: {},
  validToken: true,

  setValidToken(data) {
    this.validToken = data;
  },

  setUserData(data) {
    this.userData = data;
  },

  setUserSession(data) {
    this.userSection = data;
  },

  setNoAccessToken(data) {
    this.noAccessToken = data;
  },

  getAllsections(item) {
    this.sections = item;
  },

  filterSections(id) {
    this.renderSection = this.sections.filter(ele => ele.id === id);
  },
  setActivePage(id) {
    this.activePage = id;
  },
  setCollectionJson(data) {
    this.collectionJson = data;
  },

  setFetchProductState(data) {
    this.fetchProductState = data;
  },

  openSectionPanel() {
    this.toggleSectionPanel = true;
  },

  closeSectionPanel() {
    this.toggleSectionPanel = false;
  },
  setConnection(value) {
    this.checkConnection = value;
  },

  toggleFetchDataFlag() {
    this.fetchDataFlag = !this.fetchDataFlag;
  },

  setAccessToken(data) {
    this.accessToken = data;
  },

  setLoading(flag) {
    this.loading = flag;
  },

  setUploadData(data) {
    this.uploadData = data;
  },

  toggleFetchUploadDataFlag() {
    this.fetchUploadDataFlag = !this.fetchUploadDataFlag;
  },

  setSaveModalState(flag) {
    this.SaveModalState = flag;
  },

  setBlockerModalState(flag) {
    this.blockerModalState = flag;
  },

  setBlockerModalType(type) {
    this.blockerModalType = type;
  },

  setWarningModalState(flag) {
    this.warningModalState = flag;
  },

  setCollectionJsonState(flag) {
    this.collectionJsonState = flag;
  },
});

export default sectionStore;
