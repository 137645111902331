import axios from 'axios';
import sectionStore from '../store/sectionStore';

// Create a base instance of axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVICE,
});

axiosInstance.interceptors.request.use(
  config => {
    // Get access token from sectionStore

    const access_token = sectionStore.accessToken;
    // Set access token as header and params
    config.headers['access-token'] = access_token;
    config.params = {
      ...config.params,
      access_token: access_token,
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const statusCode = error.response ? error.response.status : null;
    console.log('error', error);
    if (statusCode === 500 || error?.code === 'ERR_NETWORK') {
      sectionStore.setLoading(false);
      sectionStore.setBlockerModalType('500');
      sectionStore.setBlockerModalState(true);
      return Promise.reject('Internal Server Error');
    } else {
      return Promise.reject(error);
    }
  }
);

const API_KEY = {
  URL: axiosInstance,
  path: {
    products: 'polotno/data_collector',
    layouts: 'polotno/layouts',
    attributes: 'attribute',
    templates: 'polotno/templates/',
    recentTemplate: 'polotno/recent_version/',
    downloadppt: 'polotno/ppt/download',
    save: 'polotno/recent_version/save',
    manufacturer: 'user/me',
    referenceImages: 'polotno/reference_image/',
    session: 'analytics/session/',
    getdetails: 'analytics/getdetails/',
  },
};

export default API_KEY;
