export const Messages = {
  templateChangeMessage:
    'You will lose changes made if you change the template',
  loaderModalText: 'We’re making it awesome for you',
  loaderModalSubText: 'Just a few minutes...',
  layoutChangeMessage:
    'You will lose changes made if you change the layout of the current page',
  layoutTypeChangeMessage:
    'Changing the layout from portrait to landscape, or vice versa, will affect all the slides in your presentation. Are you sure you want to proceed with this action?',
  ErrorMessageText401:
    'You don’t have the permission to access catalogue studio',
  ErrorMessageSubText401: 'Contact the account admin for more details',
  ErrorMessageText404: 'Seems like you’re lost',
  ErrorMessageSubText404: '404 - page not found',
  ErrorMessageText500:
    'Apologies, we are experiencing system issues. Save your work now to preserve your unsaved changes.',
  ErrorMessageText500NotValidToken:
    "Apologies, we're currently experiencing system issues.",
  ErrorUploadingSavePreview:
    'Preview upload unsuccessful. Please check your internet connection and retry. If the issue persists, contact our Customer Support team',
  ErrorMessageText500Full:
    'You don’t have the permission to access catalogue studio',
  ErrorMessageSubText500:
    'We are actively working on resolving the technical difficulties.',
  deleteImageMessage: 'Are you sure you want to delete?',
  ErrorMessageTextBoundary:
    'Apologies, an issue occurred. Please reload the page. If unresolved, contact support',
  ErrorMessageSubTextBoundary:
    'Rest assured, we are investigating this matter and we will update as soon as possible',
  PPTDownloadMainMessage: 'Download PPT file',
  PPTDownloadSubMessage:
    'Obtain the presentation; optimal viewing in Google Slides',
  PDFDownloadMainMessage: 'Download PDF file',
  PDFDownloadSubMessage:
    'Secure the PDF; ensures consistent viewing across devices.',
  Section: {
    Products: 'Products',
    Templates: 'Templates',
    Layout: 'Layouts',
    Shapes: 'Elements',
    Text: 'Text',
    Uploads: 'Uploads',
    Icons: 'Icons',
    Background: 'Backgrounds',
  },
  PhotoroomAPIError:
    'Apologies for the inconvenience. The image background removal process has encountered an issue. Please try again, or contact support if the problem persists.',
  PhotoroomAPIUploadError:
    'We apologize for the inconvenience. We are currently experiencing difficulties processing your request. Please try again later, or contact our support team if the issue persists.',
};
