import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';

const propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.style || PropTypes.object,
  disabled: PropTypes.bool,
};

function Index({ disabled, title, className, style, ...props }) {
  return (
    <Button
      {...props}
      disabled={disabled}
      style={{ ...style }}
      intent="secondary"
      className={disabled ? 'btn__disable' : `btn btn__${className}`}
    >
      {title}
    </Button>
  );
}

Index.propTypes = propTypes;
export default Index;
