import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light-theme'); // Change this to 'light-theme'

  const toggleTheme = () => {
    setTheme(currentTheme =>
      currentTheme === 'light-theme' ? 'dark-theme' : 'light-theme'
    ); // Update the values here as well
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
