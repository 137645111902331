import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  subText: PropTypes.string,
  children: PropTypes.element,
  textStyle: PropTypes.object,
  imgStyle: PropTypes.object,
};

function Index({
  image,
  text,
  subText,
  children,
  textStyle,
  imgStyle,
  ...props
}) {
  let imageStyle = {};
  if (imgStyle?.width) {
    imageStyle = imgStyle;
  }
  return (
    <div className="screen" {...props}>
      <img
        style={{ ...imageStyle }}
        className="screen__img"
        src={image}
        alt="error image"
      />
      <span style={{ ...textStyle }} className="screen__text">
        {text}
      </span>
      <span className="screen__sub__text">{subText}</span>
      {children}
    </div>
  );
}

Index.propTypes = propTypes;
export default Index;
