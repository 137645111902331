import React, { useEffect, useRef, useState } from 'react';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import { createStore } from 'polotno/model/store';
import sectionStore from './store/sectionStore';
import { observer } from 'mobx-react-lite';
import ErrorBoundary from './errors/ErrorBoundary';
import Tracker from '@openreplay/tracker';
import { getManufacturerInfoApi } from './api/getManufacturerInfo';
import Cookies from 'js-cookie';
import AppRouter from './routes';
import './assets/less/main.less';
import './App.css';
import * as Sentry from '@sentry/react';
import { productFruits } from 'product-fruits';
import eventTracker from './analytics/tracker';

const store = createStore({
  key: 'HFCl1AQe1zFAF71HITVD',
  showCredit: true,
});

const tracker = new Tracker({
  projectKey: `${process.env.REACT_APP_OPENREPLAY_PROJECT_KEY}`,
});

store.setUnit({ unit: 'px', dpi: 72 });
store.setSize(1814.4, 1028.88);
store.addPage();

const App = observer(() => {
  const [accessToken, setAccessToken] = useState(Cookies.get('access_token'));
  const [prodFruitInit, setProdFruitInit] = useState(false);
  const accessTokenRef = useRef(accessToken);
  useEffect(() => {
    if (sectionStore.accessToken) {
      handleGetUserData();
    } else {
      sectionStore.setValidToken(false);
    }
    // eslint-disable-next-line
  }, [sectionStore.accessToken]);

  const handleGetUserData = async () => {
    getManufacturerInfoApi()
      .then(res => {
        if (res?.status === 200) {
          sectionStore.setValidToken(true);
          let userData = res?.data?.data;
          Sentry.setUser({
            email: userData?.email,
            id: userData?.id,
          });
          sectionStore.setUserData(userData);
          if (process.env.REACT_APP_ENV === 'prod') {
            tracker
              .start({
                userID: userData?.first_name + ' ' + userData?.last_name,
                metadata: {
                  email: userData?.email,
                  mobile: userData?.mobile,
                  country: userData?.country,
                  isAdmin: userData?.is_admin,
                  userId: userData?.id,
                },
              })
              .then(startedSession => {
                if (startedSession.success) {
                  console.log('session started:', startedSession);
                }
              })
              .catch(err => {
                console.log('Open Replay Error', err);
              });
            if (!prodFruitInit) {
              setProdFruitInit(true);
              productFruits.init(
                `${process.env.REACT_APP_PRODUCT_FRUIT_WORKSPACE}`,
                'en',
                {
                  username: userData?.id,
                  email: userData?.email,
                  firstname: userData?.first_name,
                  lastname: userData?.last_name,
                },
                {
                  disableLocationChangeDetection: true,
                }
              );
            }
          }
        }
      })
      .catch(err => {
        sectionStore.setValidToken(false);
        sectionStore.setLoading(false);
        sectionStore.setBlockerModalType('401');
        console.log(
          err?.response?.data?.message || err,
          'danger',
          ' getManufacturerInfoApi'
        );
      });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const newAccessToken = Cookies.get('access_token');
      if (sectionStore.accessToken !== newAccessToken) {
        sectionStore.setAccessToken(newAccessToken);
      }
      if (newAccessToken !== accessTokenRef.current) {
        setAccessToken(newAccessToken);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    accessTokenRef.current = accessToken;
    if (!accessToken) {
      // showNotification('not valid token');
      sectionStore.setAccessToken(null);
      sectionStore.setBlockerModalType('401');
      sectionStore.setBlockerModalState(true);
      sectionStore.setNoAccessToken(true);
      sectionStore.setLoading(false);
    } else {
      sectionStore.setBlockerModalState(false);
    }
    // eslint-disable-next-line
  }, [accessToken]);

  return (
    <React.Fragment>
      <ErrorBoundary>
        <AppRouter store={store} />
      </ErrorBoundary>
    </React.Fragment>
  );
});

export function handleBeforeUnload(e) {
  e.preventDefault();
  e.returnValue = '';
}

export default eventTracker(App);
